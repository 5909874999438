import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Container, ContentWrapper } from '../helpers';
import { mediaMd, mediaSm, NAVIGATION, NavItem } from '../constants';
import { Mail } from '@styled-icons/entypo/Mail';
import { LinkedinSquare } from '@styled-icons/boxicons-logos/LinkedinSquare';
import { Link } from 'react-router-dom';
import NavigationBar from '../NavigationBar';

const backgrounds = ['bg2.jpg', 'bg1.jpg', 'bg3.jpg'];

function Home() {
  let backgroundInterval: any = null;
  const [bgIndex, setBgIndex] = useState(0);

  useEffect(() => {
    // autoPlay();
    return cleanup;
  }, []);

  return (
    <HomeContainer background={backgrounds[bgIndex]}>
      <NavigationBar />
      {/* <Blur /> */}
      <HomeContentWrapper>
        <Name>
          Rachna <b>Iyer</b>
        </Name>
        <IntroWrapper>
          <Intro>Aspiring Pychologist, Poet, Musician</Intro>
        </IntroWrapper>
        <IconWrapper>
          <MailLink href="mailto:rachnai@umich.edu">
            <MailIcon />
          </MailLink>
          <LinkedinLink
            href="https://www.linkedin.com/in/rachna-iyer/"
            target="_blank"
          >
            <LinkedinIcon />
          </LinkedinLink>
        </IconWrapper>
      </HomeContentWrapper>
    </HomeContainer>
  );

  function autoPlay() {
    backgroundInterval = setInterval(() => {
      setBgIndex((bgIndex) => (bgIndex + 1) % backgrounds.length);
    }, 4000);
  }

  function cleanup() {
    if (backgroundInterval) {
      clearInterval(backgroundInterval);
      backgroundInterval = null;
    }
  }
}

export default Home;

const HomeContainer = styled(Container)<{ background: string }>`
  /* background: ${({ background }) => `url('home/${background}')`}; */
  /* background: url('assets/home/bg1.jpg'); */
  /* background: url('assets/home/bg2.jpg'); */
  /* background: url('assets/home/bg3.jpg'); */
  transition: background 500ms ease-in;
  background-attachment: fixed;
  background-size: cover;
  height: 100vh;
  margin-top: 0;
  overflow: hidden;
`;

const HomeContentWrapper = styled(ContentWrapper)`
  padding: 4rem;
`;

const Intro = styled.div`
  padding: 8px 32px;
  display: inline-block;
  background: rgba(255, 255, 255, 0.4);
  border-radius: 8px;
  font-size: 2rem;
  ${mediaSm} {
    font-size: 1.5rem;
    text-align: center;
  }
`;

const IntroWrapper = styled.div`
  margin-top: 3vh;
  margin-bottom: 6vh;
  ${mediaMd} {
    display: flex;
    justify-content: center;
  }
`;

const IconWrapper = styled.div`
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${mediaMd} {
    width: auto;
    justify-content: center;
  }
`;

const MailIcon = styled(Mail)`
  height: 55px;
  width: 55px;
  cursor: pointer;
  transition: color 150ms linear;
  color: white;
  &:hover {
    color: var(--accent);
  }
  ${mediaMd} {
    margin: 0px 16px;
  }
`;

const LinkedinIcon = styled(LinkedinSquare)`
  height: 55px;
  width: 55px;
  cursor: pointer;
  transition: color 150ms linear;
  color: white;
  &:hover {
    color: var(--accent);
  }
  ${mediaMd} {
    margin: 0px 16px;
  }
`;

const LinkedinLink = styled.a``;

const MailLink = styled(LinkedinLink)``;

const Name = styled.div`
  margin-top: 12vh;
  font-family: 'Playfair Display', serif;
  letter-spacing: 0;
  display: inline-block;
  align-items: center;
  position: relative;
  font-size: 8em;
  width: 690px;
  b {
    color: var(--accent);
    font-weight: normal;
  }

  ${mediaMd} {
    margin-top: 16vh;
    text-align: center;
    font-size: 7rem;
  }

  ${mediaSm} {
    margin-top: 16vh;
    text-align: center;
    font-size: 5rem;
    width: auto;
  }
`;
