import React, { useRef, useState } from 'react';
import styled from '@emotion/styled';
import { ChevronDown } from '@styled-icons/boxicons-regular/ChevronDown';
import { Date } from '../Poems/interfaces';
import { mediaSm } from '../constants';

function Collapsible({
  title,
  subtitle,
  date,
  children,
}: {
  title: string;
  subtitle?: string;
  date?: Date;
  children: any;
}) {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [maxHeight, setMaxHeight] = useState(2000);
  const [heightHasBeenAdjusted, setHeightHasBeenAdjusted] = useState(false);
  const [disableToggle, setDisableToggle] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);

  function toggleCollapsed() {
    setIsCollapsed(!isCollapsed);
    setTimeout(() => {
      if (
        !heightHasBeenAdjusted &&
        contentRef.current &&
        contentRef.current.getBoundingClientRect().height < maxHeight
      ) {
        setHeightHasBeenAdjusted(true);
        setMaxHeight(contentRef.current.getBoundingClientRect().height);
      }
    }, 500);
  }

  return (
    <Container>
      <Header
        onClick={() => {
          if (disableToggle) return;
          setDisableToggle(true);
          toggleCollapsed();
          setTimeout(() => {
            setDisableToggle(false);
          }, 500);
        }}
      >
        <Arrow isCollapsed={isCollapsed} />
        <TitleWrapper>
          <Title>{title}</Title>
          {subtitle && (
            <SubTitle dangerouslySetInnerHTML={{ __html: subtitle }}></SubTitle>
          )}
        </TitleWrapper>
        {date && <DateComposed>{`${date.month}, ${date.year}`}</DateComposed>}
      </Header>
      <Collapse
        ref={contentRef}
        isCollapsed={isCollapsed}
        maxHeight={maxHeight}
      >
        <Content>{children}</Content>
      </Collapse>
    </Container>
  );
}

export default Collapsible;

const Container = styled.div`
  width: 100%;
  background: rgba(255, 255, 255, 0.4);
  color: black;
  border-radius: 1rem;
`;

const Arrow = styled(ChevronDown)<{ isCollapsed: boolean }>`
  position: absolute;
  height: 25px;
  width: 25px;
  color: rgba(0, 0, 0, 0.6);
  transition: transform 300ms ease-out;
  transform: ${({ isCollapsed }) =>
    isCollapsed ? 'rotate(0)' : 'rotate(180deg)'};
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 2rem;
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 1.2rem;
  text-align: left;
  font-style: italic;

  ${mediaSm} {
    font-size: 1.1rem;
  }
`;

const SubTitle = styled.div`
  font-size: 0.8rem;
  font-style: italic;
  white-space: pre-wrap;
  margin: 0rem 0px -0.3rem 1rem;
`;

const DateComposed = styled.div`
  position: absolute;
  font-size: 0.7rem;
  top: 1rem;
  right: 1rem;
  font-weight: bold;
  background: rgba(0, 0, 0, 0.2);
  padding: 4px 8px 4px 8px;
  border-radius: 16px;

  ${mediaSm} {
    top: -10px;
    right: -10px;
  }
`;

const Header = styled.div`
  cursor: pointer;
  transition: background 100ms ease-in-out;
  position: relative;
  padding: 0.8rem;
  display: flex;
  align-items: center;

  :active {
    background: rgba(0, 0, 0, 0.1);
  }
`;

const Content = styled.div`
  font-size: 1.2rem;
`;

const Collapse = styled.div<{ isCollapsed: boolean; maxHeight: number }>`
  display: flex;
  justify-content: center;
  overflow: hidden;
  ::-webkit-transition {
  }
  transition: max-height 300ms ease-out;
  max-height: ${({ isCollapsed, maxHeight }) =>
    isCollapsed ? '0px' : `${maxHeight}px`};
`;
