import React from 'react';
import styled from '@emotion/styled';
import {
  EvenlySpacedColumn,
  Container,
  ContentWrapper,
  GradientText,
  HeaderBoilerplate,
  IntroBoilerplate,
  Row,
  Title,
} from '../helpers';
import NavigationBar from '../NavigationBar';
import Quotes from './Quotes';
import Music from './Music';
import Movies from './Movies';
import Books from './Books';
import { mediaMd, mediaSm } from '../constants';

function About() {
  return (
    <>
      <Container>
        <NavigationBar />
        <ContentWrapper>
          <Header>
            <Title>
              <GradientText>About Me</GradientText>
            </Title>
            <IntroWrapper>
              <PortraitWrapper>
                <Portrait src="portrait.jpg" />
              </PortraitWrapper>
              <EvenlySpacedColumn>
                <Intro>
                  Hi! Welcome. I’m <b>Rachna</b>, a junior at the{' '}
                  <b>University of Michigan, Ann Arbor</b>, from Pune, India,
                  but also a little bit of all over the world.
                </Intro>
                <Intro>
                  My enduring passion has always been psychology, even before I
                  knew the true definition of the word. I am majoring in{' '}
                  <b>Psychology</b> and aspire to do an <b>Honors Thesis</b> and{' '}
                  <b>graduate early</b>. I have always loved writing and reading
                  literature, which inspired me to add a second major in{' '}
                  <b>English</b> with a sub-concentration in{' '}
                  <b>Creative Writing</b>.
                </Intro>
              </EvenlySpacedColumn>
            </IntroWrapper>
            <SubIntro>
              After completing my undergrad, I hope to enroll in a{' '}
              <b>Clinical Psychology PhD program</b>. My career goal is to
              become a <b>clinical psychologist</b>,{' '}
              <b>make mental health more accessible</b> to every population, and
              perhaps integrate my English Creative Writing major into my career
              by <b>exploring creative therapy</b>.
            </SubIntro>
            <ActionText>
              Scroll down to read more about my interests, passions, and
              hobbies!
            </ActionText>
          </Header>
          <PageInformation>
            <Quotes />
            <Music />
            <Movies />
            <Books />
          </PageInformation>
        </ContentWrapper>
      </Container>
    </>
  );
}

export default About;

const PortraitWrapper = styled.div`
  display: flex;
  ${mediaMd} {
    justify-content: center;
  }
`;

const PageInformation = styled.div`
  padding: 0 2rem;

  ${mediaSm} {
    padding: 0 1rem;
  }
`;

const IntroWrapper = styled(Row)`
  ${mediaMd} {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

const Portrait = styled.img`
  max-width: 200px;
  min-width: 200px;
  max-height: 200px;
  min-height: 200px;
  border: 2px solid var(--accent);
  border-radius: 50%;
  object-fit: cover;

  ${mediaMd} {
    max-width: 175px;
    min-width: 175px;
    max-height: 175px;
    min-height: 175px;
  }

  ${mediaSm} {
    max-width: 150px;
    min-width: 150px;
    max-height: 150px;
    min-height: 150px;
  }
`;

const Intro = styled(IntroBoilerplate)`
  margin-left: 24px;
  ${mediaMd} {
    margin-left: 0px;
    margin-top: 12px;
    text-align: center;
    font-size: 1rem;
  }
`;

const SubIntro = styled(IntroBoilerplate)`
  margin-top: 12px;
  ${mediaMd} {
    text-align: center;
    font-size: 1rem;
  }
`;

const Header = styled(HeaderBoilerplate)``;

const ActionText = styled.div`
  font-size: 1.1rem;
  text-align: center;
  margin: 24px 0px;
`;
