import React from 'react';
import styled from '@emotion/styled';
import {
  Container,
  ContentWrapper,
  GradientText,
  HeaderBoilerplate,
  IntroBoilerplate,
  Title,
} from '../helpers';
import NavigationBar from '../NavigationBar';
import { mediaMd, mediaSm, NAVIGATION } from '../constants';
import { Link } from 'react-router-dom';

function Contact() {
  return (
    <Container>
      <NavigationBar />
      <ContentWrapper>
        <Header>
          <Title>
            <GradientText>Contact Me</GradientText>
          </Title>
          <Intro>
            Please feel free to talk to me about my{' '}
            <TextLink to={NAVIGATION.ABOUT.route}>interests</TextLink>,
            opportunities, or anything else! I'm excited to hear from you!
          </Intro>
        </Header>
        <EmailWrapper>
          <Email href={'mailto:rachai@umich.edu'}>rachnai@umich.edu</Email>
        </EmailWrapper>
      </ContentWrapper>
    </Container>
  );
}

export default Contact;

const Intro = styled(IntroBoilerplate)`
  margin-top: 12px;
  font-size: 1.5rem;
`;

const Header = styled(HeaderBoilerplate)``;

const TextLink = styled(Link)`
  color: var(--accent);
  text-decoration: underline;
`;

const EmailWrapper = styled.div`
  margin-top: 60px;
  font-family: 'Montserrat';
  display: flex;
  justify-content: center;
  padding: 0 2rem;
`;

const Email = styled.a`
  background: rgba(255, 255, 255, 0.4);
  padding: 1rem 2rem;
  border-radius: 12px;
  font-size: 4rem;
  color: var(--accent);
  text-decoration: none;

  ${mediaMd} {
    font-size: 3rem;
  }

  ${mediaSm} {
    font-size: 1.8rem;
    padding: 1rem;
  }
`;
