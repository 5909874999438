import React from 'react';
import styled from '@emotion/styled';
import {
  Interest,
  InterestAbout,
  InterestHeader,
  InterestTitle,
} from '../helpers';
import { BOOKS } from '../constants';
import Book from './Book';
import { Books as StackedBooks } from '@styled-icons/icomoon/Books';

function Books() {
  return (
    <Container>
      <InterestHeader>
        <InterestTitle>Books</InterestTitle>
        <BooksIcon />
      </InterestHeader>
      <InterestAbout>
        It is a truth universally acknowledged that the best way to write is to
        read. I have been diving into the pages of books and living in worlds
        outside my own for as long as I can remember (my origin story starts
        with Junie B. Jones). Stories tell me more about human nature, which I
        find tells me more about myself.
      </InterestAbout>
      <BooksWrapper>
        {BOOKS.map((book) => {
          return <Book book={book} />;
        })}
      </BooksWrapper>
    </Container>
  );
}

export default Books;

const Container = styled(Interest)``;

const BooksWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-top: 32px;
`;

const BooksIcon = styled(StackedBooks)`
  margin-left: 12px;
  color: white;
  height: 50px;
  width: 50px;
`;
