import React from 'react';
import styled from '@emotion/styled';
import DesktopNav from './DesktopNav';
import PhoneNav from './PhoneNav';

function NavigationBar() {
  return window.innerWidth < 900 ? <PhoneNav /> : <DesktopNav />;
}

export default NavigationBar;
