import React from 'react';
import { Howl } from 'howler';
import styled from '@emotion/styled';
import { Quote as QuoteType, Line as LineType } from '../interfaces';
import { mediaMd } from '../../constants';

function Quote({ quote, sound }: { quote: QuoteType; sound: Howl }) {
  return (
    <Container
      onMouseEnter={() => {
        if (sound.state() === 'loaded') {
          sound.play();
        }
      }}
    >
      <ContentWrapper>
        <DialogueWrapper>
          {quote.dialogue.map((line: LineType) => {
            return (
              <Dialogue>
                <DialogueSpeaker>
                  {line.person && line.context
                    ? `${line.person}, `
                    : line.person
                    ? `${line.person}: `
                    : ''}
                </DialogueSpeaker>
                <DialogueContext>
                  {line.context && `${line.context}: `}
                </DialogueContext>
                <DialogueLine>{line.line}</DialogueLine>
              </Dialogue>
            );
          })}
        </DialogueWrapper>
        <Date>
          <Line>{quote.person && `${quote.person}, `}</Line>
          <Line>{quote.date.context && `${quote.date.context}, `}</Line>
          <Line>{quote.date.month && `${quote.date.month} `}</Line>
          <Line>{quote.date.year}</Line>
        </Date>
      </ContentWrapper>
    </Container>
  );
}

export default Quote;

const Container = styled.div`
  font-family: 'Montserrat';
  font-size: 0.9rem;
  margin: 0px 16px;
  margin-bottom: 12px;
  background: rgba(255, 255, 255, 0.6);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: box-shadow 200ms linear, transform 200ms linear;

  :hover {
    transform: translateY(-6px);
    box-shadow: 6px 6px 6px rgba(0, 0, 0, 0.4);
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 8px 16px;
  width: 400px;

  ${mediaMd} {
    width: 300px;
  }

  @media (max-width: 865px) {
    width: 250px;
  }
`;

const DialogueWrapper = styled.div``;

const Dialogue = styled.div`
  margin-bottom: 6px;
`;

const Line = styled.text`
  ${mediaMd} {
    font-size: 0.9rem;
  }
`;

const DialogueLine = styled(Line)``;

const DialogueSpeaker = styled(Line)`
  font-weight: bold;
  color: #2323d3;
`;

const DialogueContext = styled(DialogueSpeaker)``;

const Date = styled.div`
  margin-top: 6px;
  width: fit-content;
  padding: 4px;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.13);
  font-size: 0.7rem;

  ${mediaMd} {
    font-size: 0.6rem;
  }
`;
