import { Months } from '../Poems/interfaces';
import { Book, Movie, Quote } from './interfaces';

export const QUOTES: Quote[] = [
  {
    dialogue: [
      {
        person: 'Rachna',
        context: "Greeting people at the Lawyer's club",
        line: '“Have a nice meal!”',
      },
      {
        person: 'Everyone',
        line: '“Thanks, you too!”',
      },
    ],
    date: {
      month: Months.OCTOBER,
      year: 2019,
    },
  },
  {
    dialogue: [
      {
        line:
          '“My mom just calls me by saying “girls!” even if it’s just me, and I’m like, who are you talking to, me and my four personalities?”',
      },
    ],
    date: {
      month: Months.JANUARY,
      year: 2020,
    },
    person: 'Sravani',
  },
  {
    dialogue: [
      {
        line: '“We’re just scientific people... doing non-scientific things.”',
      },
    ],
    date: {
      month: Months.NOVEMBER,
      year: 2019,
    },
    person: 'Navya',
  },
  {
    dialogue: [
      {
        person: 'Rachna',
        line: '“Does anybody have any book recommendations?”',
      },
      {
        person: 'Aniket',
        line: '“Yeah, Netflix.”',
      },
    ],
    date: {
      month: Months.AUGUST,
      year: 2020,
    },
    person: 'Aniket',
  },
  {
    dialogue: [
      {
        person: 'Hrudit',
        line: '“I was addicted to Wii”',
      },
      {
        person: 'Rachna',
        line: '“Well, I had a life”',
      },
      {
        person: 'Hrudit',
        line: '“What console is that?”',
      },
    ],
    date: {
      month: Months.OCTOBER,
      year: 2019,
    },
    person: 'Hrudit',
  },
  {
    dialogue: [
      {
        line:
          '“I don’t get why people call it ‘denim’ and not just ‘jeans material’.”',
      },
    ],
    date: {
      month: Months.OCTOBER,
      year: 2020,
    },
    person: 'Aniket',
  },
  {
    dialogue: [
      {
        line:
          '“Navya, you’re the MSU of friends - when people can’t get their first choice of friend, they come to you.”',
      },
    ],
    date: {
      month: Months.JANUARY,
      year: 2020,
    },
    person: 'Pulak',
  },
  {
    dialogue: [
      {
        person: 'Rachna',
        line:
          '“Don’t talk about bollywood music, it reminds me that I haven’t learned my dance.”',
      },
      {
        person: 'Shaan',
        line: '“Oh, when’s it due?”',
      },
    ],
    date: {
      context: 'Finals week',
      year: 2019,
    },
  },
  {
    dialogue: [
      {
        person: 'Rachna',
        line: '“What’s up, madu? haven’t seen you in a while!”',
      },
      {
        person: 'Madu',
        line: '“Yeah, i’ve been super sleepy for the last six months."',
      },
    ],
    date: {
      month: Months.SEPTEMBER,
      year: 2020,
    },
    person: 'Madu',
  },
  {
    dialogue: [
      {
        line:
          '“Rachna, if you were a covidopoly card, you’d be toilet paper stash.”',
      },
    ],
    date: {
      month: Months.AUGUST,
      year: 2020,
    },
    person: 'Pulak',
  },
];

export const MOVIES: Movie[] = [
  {
    name: 'About Time',
    about:
      'This isn’t usually my genre of choice, but About Time is so cute and heartfelt that it takes rom-coms to whole new level. It’s the only movie where I’ve cried out of happiness!',
    imageUrl: '/movies/about-time.jpg',
  },
  {
    name: 'Eternal Sunshine of the Spotless Mind',
    about:
      'The Psychology nerd in me will always be obsessed with the idea of erasing someone you love from your memory but finding your way back to them.',
    imageUrl: '/movies/eternal-sunshine.jpg',
  },
  {
    name: 'Her',
    about:
      'This movie tackles so much. Love, friendship, loneliness, technology. It also has some of the best cinematography, in my opinion -- I would love to see a montage of Joaquin Phoenix in a bright pink shirt strumming his ukulele on the beach.',
    imageUrl: '/movies/her.jpg',
  },
  {
    name: 'The Pursuit of Happyness',
    about:
      'This was my first favorite movie, and no matter how many times I watch it, I never fail to ugly-cry at the subway scene.',
    imageUrl: '/movies/happyness.png',
  },
  {
    name: 'Blue Valentine',
    about:
      'This movie showed one of my favourite realistic depictions of human relationships -- sometimes it feels like it’s meant to be, but it isn’t what’s best for you.',
    imageUrl: '/movies/blue-valentine.jpg',
  },
];

export const BOOKS: Book[] = [
  {
    title: 'The Awakening',
    author: 'Kate Chopin',
    about:
      'Usually, books you read in <b>Literature classes don’t end up on the favorites list</b> because you might tend to study them rather than read them, but this only made me love The Awakening even more. I hunched over this book for <b>two years</b> of my IB literature course; I can still <b>name quotes</b> from it word for word, but there is so much I now know about the <b>pure genius of its writing</b> that I will forever be a fan.',
  },
  {
    title: 'A Thousand Splendid Suns',
    author: 'Khaled Hosseini',
    about:
      'Every time I even think about this book, and the journey and the hardships that the <b>female protagonists</b> underwent, I feel more and more strongly about my <b>proud feminism</b> and admiration for <b>women of color</b> everywhere. I would be remiss if I did not mention that my <b>favorite quote of anything</b>, <b>anywhere</b>, <b>of all time</b>, was written by Hosseini in this book - ',
    quote:
      "“A man's heart is a wretched, wretched thing. It isn't like a mother's womb. It won't bleed. It won't stretch to make room for you.”",
  },
  {
    title: 'The Kite Runner',
    author: 'Khaled Hosseini',
    about:
      'This book <b>mends and breaks my heart</b> in so many ways, and is often what I think of when I picture books and writing that <b>truly inspire me</b>.',
  },
  {
    title: 'Tuesdays With Morrie',
    author: 'Mitch Albom',
    about:
      'This book taught me so much about <b>life</b> and <b>family</b> and <b>love</b> in a way that <b>fiction books cannot</b>, but in a way that most <b>non-fictional books would not</b>.',
  },
  {
    title: 'The Bell Jar',
    author: 'Sylvia Plath',
    about:
      '<b>Plath was truly a phenomenal woman and writer</b>; her poetry always leaves me in awe and ever since I <b>read this book at fifteen</b>, it has been a favorite. I owe it <b>many re-reads</b>.',
  },
];
