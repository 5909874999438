import React from 'react';
import styled from '@emotion/styled';
import {
  Container,
  ContentWrapper,
  GradientText,
  HeaderBoilerplate,
  IntroBoilerplate,
  Title,
} from '../helpers';
import NavigationBar from '../NavigationBar';
import { EXPERIENCES } from './constants';
import WorkExperience from './WorkExperience';
import { mediaMd } from '../constants';

function Experience() {
  return (
    <Container>
      <NavigationBar />
      <ContentWrapper>
        <Header>
          <Title>
            <GradientText>My Experience</GradientText>
          </Title>
          <Intro>
            Here is everything <b>productive</b> and <b>resume-worthy</b> I have
            done with my time so far. I am very privileged to have had the
            opportunity to <b>experiment</b> with many <b>different jobs</b> and{' '}
            <b>industries</b>, exploring all of my <b>interests</b> from{' '}
            <b>psychology</b>, <b>writing</b>, and <b>creativity</b> to{' '}
            <b>social media</b>, <b>social work</b> and <b>food service</b>.
          </Intro>
          <ActionText>
            Here are some of my <b>most enriching experiences</b>!
          </ActionText>
        </Header>
        <ExperienceListWrapper>
          {EXPERIENCES.map((experience) => {
            return <WorkExperience experience={experience} />;
          })}
        </ExperienceListWrapper>
      </ContentWrapper>
    </Container>
  );
}

export default Experience;

const Intro = styled(IntroBoilerplate)``;

const Header = styled(HeaderBoilerplate)``;

const ActionText = styled(IntroBoilerplate)`
  margin-top: 1.5rem;
  text-align: center;
`;

const ExperienceListWrapper = styled.div`
  margin-top: 2rem;
  padding: 0px 2rem;
  display: flex;
  flex-direction: column;

  ${mediaMd} {
    padding: 0px 1rem;
  }
`;
