import { Months, Poem } from './interfaces';

export const PoemList: Poem[] = [
  {
    title: 'self-portrait as the women who precede me',
    verses: [
      [
        'on my right hand, I wear my mother.',
        'I listen for the ticks in her old college watch,',
        'the seconds telling me <i>catch your bus before it leaves</i>, and',
        'I think of all she has given me.',
        'her time                          me, life                lessons on independence.',
      ],
      [
        'I taste my mother in my tears',
        'as we sniffle and turn to stare at each other’s wet, salty faces',
        'in the middle of this dark and cold, popcorn-sweat theatre',
        'in the middle of this story about a crazy man',
        'in the middle of this tight sailor’s knot of our lives.',
      ],
      [
        'I am my mother, and I am my mother’s mother',
        'when I stand, glistening, over the stove',
        'stirring spices and',
        'a little extra sugar never hurt anybody, right?',
        'we leave the last piece untouched.',
      ],
      [
        'I hear my mother in my silence',
        'as we swallow our desires, lumps of',
        'soggy-dry cardboard, halfway lodged',
        'in our throats like forgotten promises.',
        'the difference lies in my cowardice.',
      ],
      [
        'yet',
        'I am my mother in my rage.',
        'her won’t-take-no-for-an-answer',
        'her this-is-not-what-I-want-this-is-what-I-want-for-you.',
        'maybe someday I will act when I know what motherhood feels like.',
      ],
      [
        'in my deep brown eyes, I wear my mother.',
        'she is in the lumps on my body,',
        '                            that one left incisor that just slightly yellower than the rest,',
        '                            the dark hair on my upper lip that I pluck off my face.',
        'maybe I should keep them. ',
        'maybe I should wear my mother on my face with pride. ',
      ],
    ],
    dateComposed: {
      month: Months.FEBRUARY,
      year: 2020,
    },
  },
  {
    title: 'Rocket Scientist',
    subtitle: '- After ‘A Thousand Years of Good Prayers’ by Yiyun Li',
    verses: [
      [
        'My daddy is a rocket scientist -',
        'you must have heard of him! He engineers',
        'space-ships with his giant, calloused fist.',
        'Aliens arrive, daddy disappears.',
      ],
      [
        'My daddy is a construction worker,',
        'some architecture of some architect. ',
        'See that brick-red-roof house? Dearest father',
        'holds it up strongly, so tall and erect. ',
      ],
      [
        'My daddy is a zookeeper, desert',
        'adventurer. Once he came home after',
        'an expedition, said his left foot hurt -',
        'there’s scorpion poison in the plaster. ',
      ],
      [
        'Magician daddy, hops into his hat:',
        'girl marvels at his disappearing act.',
      ],
    ],
    dateComposed: {
      month: Months.MARCH,
      year: 2020,
    },
  },
  {
    title: 'grocery shopping for independence',
    verses: [
      [
        'we went grocery shopping for independence',
        'on a rainy sunday morning.',
        'you were driving ',
        'and crying,',
        'driving',
        'and crying.',
      ],
      [
        'I always loved it when you drove,',
        'you seemed so strong behind the steering wheel.',
        'I would play make-believe, pretending',
        'you knew exactly where you were taking us,',
        'and I was safe.',
      ],
      [
        'but then you told me you didn’t know how to do this alone',
        'as we turned a corner into the parking lot.',
        '<i>I’m right here</i>, I said, ',
        '<i>I can carry all the bags in,</i>',
        'but we both knew I couldn’t be what you wanted.',
      ],
      [
        'and so we scoured the aisles',
        'scanning barcodes on tin-can labels for ',
        'bravery',
        'breaking open the peppers to search for',
        'freedom. ',
      ],
      [
        'we went grocery shopping for independence',
        'on a rainy sunday morning',
        'and as I sat',
        'still',
        'in the passenger seat,',
        'I vowed to steal it for you.',
      ],
    ],
    dateComposed: {
      month: Months.OCTOBER,
      year: 2019,
    },
  },
  {
    title: 'Home for the Holidays',
    verses: [
      [
        'when I came home from college for the first time,',
        'I found my mother had adopted a bear.',
      ],
      [
        '<i>mother, there’s a bear in my bed</i> I exclaim',
        'but she simply enters, smiles softly and spoon feeds the bear some porridge.',
      ],
      [
        'the thing is, this giant sleeping bear looks an awful lot ',
        'like my favourite stuffed animal from the little metal ',
        'box my father gifted me when I was six.',
        'the thing is, I think my shoulder has been ',
        'displaced ever since I locked the bear in his',
        'little box and swallowed the key because I hated',
        'the way it looked at me and',
        '                                                     looked at me',
        'with its little beady eyes but never opened its 		       fucking mouth',
        'to talk to me.',
      ],
      [
        'now, I don’t believe in God but I’ll still capitalise His name',
        'and when the painting on the wall starts speaking to me ',
        'in a language I have never heard but understand perfectly,',
        'I must concede',
        '                         fall to my knees',
        'and pray that my joints be put back into place because I just',
        'want to feel',
        'whole ',
        'again.',
      ],
      [
        'so I’ll drop my suitcases and lay down',
        'next to the grizzly bear in my bed,',
        'arm around his furry, purring chest.',
        'I do not know him but it feels',
        'just       right.',
      ],
    ],
    dateComposed: {
      month: Months.SEPTEMBER,
      year: 2020,
    },
  },
  {
    title: 'hostages',
    verses: [
      [
        'hey are you up? i can’t sleep tonight because there’s a rat gnawing on the lining of my stomach and it’s ',
        'keeping me awake          but                    anyway		        i thought it would be nice to hear your voice ',
        'but i could just leave you this message if you’re busy and you can call me back when you get this	',
        '             and anyway		             i should try going to sleep because there’s a cockroach swimming in my ',
        'underwear now and i think it’s coming from inside of me but i can’t be certain oh look here come a few ',
        'more, these angsty little things		      so anyway	      would you like to get a cup of coffee sometime? ',
        'i mean with me, well i hate coffee but i’d love to hear your views on coffee over some glasses of water in ',
        'a coffee shop 		      but anyway 		             i’m sorry if i’m talking too much it seems there are a ',
        'few bees stinging the inside of my throat but it’s alright they mean well i’m just trying to get them out ',
        'to set them free they don’t belong here anyway			              have you ever wondered why love has ',
        'to feel like pests are slowly taking over each part of your body? it’s really okay if you don’t want to go ',
        'i’ve never been one for taking chances that leave me rocking back and forth in the fetal position on my ',
        'bathroom floor, knees over chest, snake coiled around neck, head in toilet--',
        '                                                                                                                             anyway',
      ],
    ],
    dateComposed: {
      month: Months.SEPTEMBER,
      year: 2020,
    },
  },
  {
    title: 'The Secret to Beauty',
    verses: [
      [
        'I.',
        'is chopping up your bones into finely diced slivers',
        'and leaving them to simmer in a broth for',
        'four to six hours, best results if left overnight,',
        'and sipping on it every morning --',
      ],
      [
        'II.',
        '-- every morning, ',
        'this is when you will always like the brown in your face best:',
        'after you have washed it, twice, off the sins of last night.',
      ],
      [
        'III.',
        'When you were born,',
        'the sun grabbed you by your face',
        'and kissed you on the lips. Senselessly. ',
        'My baby, you weren’t built to be',
        'stuffed into soft pink leotards,',
        'bending and stretching and contorting',
        'like the ballerinas on T.V.',
      ],
      [
        'The truth is that',
        'you got everything you needed',
        'when she touched you for the first time ',
        'on that cold november night,',
        'you have everything you need',
        'in your own warm, kettle corn marrow.',
      ],
      [
        'IV.',
        'So don’t forget to shut the curtains before you sleep because',
        'when you have silver in your face, ',
        'you develop from the negatives differently, baby.',
      ],
    ],
    dateComposed: {
      month: Months.OCTOBER,
      year: 2020,
    },
  },
  {
    title: 'Gmaj7, colorized',
    verses: [
      [
        'Human beings get through life by memorising sounds without even realising it:',
        'morning alarms                         footsteps	                  bass notes to your favourite song',
      ],
      [
        'Here lies the twirling princess trinket box, celesta sings <i>happy-birthday-to-you</i> in Gmaj7',
        'and there’s static in the baby monitor so nobody hears you slow dance around your crib',
        'cradling the faded, carnation-shaped box in the upturned palms of your hands.',
      ],
      [
        'That’s the doorbell to your home. It’s confusing, I know, but maybe if we ',
        'cut out the bell shape from the cardboard cover of your favourite movie',
        'and tape it onto the switch -- that should do the trick. Maybe it would ring more, then.',
      ],
      [
        'That, there, is your mother’s ringtone. Make sure you know that one, ',
        'it’ll be on the test, and pay special attention to the way ',
        'she closes her eyes and mutters a little prayer before answering every time it wails.',
      ],
      [
        'That’s the guitar -- those are the chords you think fit best together and',
        'yes, that’s you! Look at your smile! Listen to the laughter and the cheers',
        'and your father in a suit, all powdered up, fighting back tears.',
      ],
      [
        'Then there’s the sound of life escaping your grandmother’s throat like a heavy sigh',
        'so we run to get her a glass of cold water, hold her frail body in your arms',
        'laugh about how your sister weighs less than her.',
      ],
      [
        'Tell me the story, Ma.',
        'I’ll pretend not to hear the ugly pieces that get stuck in your throat when your voice cracks.',
        'Tell me the stories of when things were good and you were happy',
        'and I’d get tucked in with stories of crane-extracted damsels and ogres.',
      ],
      [
        '<i>There’s so much you don’t know</i>',
        '<i>Your parents were your parents for longer than you remember</i>',
        '<i>Did you know how much they mourned</i>',
        '<i>blood-clot babies before you?</i>',
        '<i>Did you know that you were a miracle</i>',
        '<i>and how your father cried when you came out of your</i>',
        '<i>mother with a halo around your head?</i>',
      ],
      [
        '<i>Did you listen to his heartbeat in your throat when he kissed you goodnight?</i>',
        '<i>Could you hear his feet shuffle before he closed the door behind him?</i>',
      ],
    ],
    dateComposed: {
      month: Months.OCTOBER,
      year: 2020,
    },
  },
  {
    title: 'In another life, I wish I knew less',
    verses: [
      [
        'I turned forty on my sixteenth birthday.',
        'Mother places an oxygen tank near the candles,',
        'there’s only three but if I blink enough times behind my ',
        'cylindrical-vision eyeglasses, I see six.',
      ],
      [
        'I was nine when I first found a grey hair sprouting out of my scalp,',
        'timid and apologetic, crowning as a newborn head. I spent hours in front of the mirror ',
        'distinguishing shine from cortisol, separating care from time.',
        'Mother tells me it will spread, but I pluck each one out anyway until I am new and clean and bald.',
      ],
      [
        'I snuck into my first R-rated movie when I was still in Mother’s womb',
        'but it wasn’t anything I hadn’t seen a thousand times before.',
        'I chase my days swimming in her unadulterated placenta and then',
        'speak my first words: <i>I was going to say something, but I forgot what it was.</i>',
      ],
      [
        'The difference between the rising moon and the setting sun isn’t much;',
        'it is the space between the floor and my legs,',
        'hanging off a high oak chair (but sometimes it has wheels),',
        'swinging to the whistling tune of my pulsating submarine stomach. ',
      ],
      [
        'Sometimes they coexist, and sometimes they don’t. ',
        'One minute I need my appendix, and the next, I don’t.',
      ],
    ],
    dateComposed: {
      month: Months.OCTOBER,
      year: 2020,
    },
  },
  {
    title: 'Tongue-tied Ways to Die',
    verses: [
      [
        'i dreamt of potstickers last night but ',
        'instead of the pot it’s my oven and',
        'instead of dumplings it’s my head and ',
        'suddenly the house is on fire and ',
        'congratulations, you’ve done it again!',
      ],
      [
        'i dreamt of a noose at the end of your body last night but',
        'instead of the rope it’s my tongue and',
        'instead of your neck it’s me, screaming and',
        'i can’t help but freeze. ',
        'listen closely to the world trapped in snow-globe motion -',
        'glass breaking and hearts beating and clocks ticking. ',
      ],
      [
        'i dreamt of great heights last night but',
        'instead of a ledge it’s my thirteenth-floor window and ',
        'this time it isn’t a dream.',
        'this time it’s really you, ',
        'leaping out.',
      ],
    ],
    dateComposed: {
      month: Months.JUNE,
      year: 2020,
    },
  },
  {
    title: 'home #9',
    verses: [
      [
        'grandfather watches over us from the wall (<i>oh, you would have loved him</i>) / and I hope he can see spring',
        'rearing its head outside. / uncle’s afternoon snores rise and fall with the ticking of the clock. / we are ',
        'on a conference call with time (<i>speak up so it can hear you</i>) and / I have not exhaled here in three days. / ',
        'the hairy rabbits wait patiently in the backyard / (<i>they  come out with the sun</i>) squealing, / <i>“this building ',
        'offers its value to everyone.”</i> // but it was too cold for his widow here (<i>maybe this is why her days are ',
        'numbered</i>) - / the background-hold music and incessant typing and / I have realised that you are only ',
        'home when you know every inch of a kitchen (<i>this is where we keep the sugar</i>). / I hide a blank slate of ',
        'possibilities and lodge it in the milk-crate coffee table (<i>do you like it? we polished it ourselves</i>). / my hair is ',
        'falling out and I am afraid of dying. or worse, growing bald. / I unpack./',
      ],
    ],
    dateComposed: {
      month: Months.APRIL,
      year: 2020,
    },
  },
  {
    title: 'home #3.12',
    verses: [
      [
        'they say you are only home when you have seen your bedroom ceiling from every possible angle, / lying ',
        'awake in your twin bed, running from the snake-infested army nightmares that chase you behind your',
        'eyelids, / so you let your head hang off the edge because the monsters pale in comparison. / the upside',
        'down doll shape above the faded pink night light scares you, / but the dark scares you even more / so',
        'you make friends with the middle-aged, spiky-haired troll and tell him your deepest secrets, and maybe',
        'he’ll tell you his. / every night in bed, you act out the same three piano keys in the shape of the Earth',
        'and you throw them into the air for the stars to catch / and then, when the song ends you’ll press your',
        'ear to the cool italian marble flooring of your snoring house // to your mother stuck outside her little',
        'cardboard box / lock. unlock. lock. / the cat soundlessly shrinks and slides under the crack in the door',
        '/ padding her soft footsteps to your bed / dead. alive. dead. / and gently, she licks your face until you',
        'fall asleep / so you sleep and you sleep and you never wake up. /',
      ],
    ],
    dateComposed: {
      month: Months.JULY,
      year: 2020,
    },
  },
  {
    title: 'Ars Poetica',
    subtitle:
      '“I called it poetry, but it was flesh and time and bread and friends.” <br />      -   Eileen Myles, Notebook, 1981',
    verses: [
      [
        'I write to preserve myself.',
        'Wrap my days in off-white linen and',
        'bury them softly in the pyramid of my existence so that',
        'when I am old and grey,',
      ],
      [
        'I can uncover these mummified feelings',
        'and let them consume me,',
        'suck me into myself like',
        'whirlwinding quicksand.',
      ],
      [
        'We do not owe poetry to mere words,',
        'nor to the books we have read. We owe',
        'our poetry to the experiences we have that give us',
        'something to say.',
      ],
    ],
    dateComposed: {
      month: Months.MARCH,
      year: 2020,
    },
  },
];

export const PoemsNeedingElongation = ['hostages', 'home #9', 'home #3.12'];
