export enum Months {
  JANUARY = 'January',
  FEBRUARY = 'February',
  MARCH = 'March',
  APRIL = 'April',
  MAY = 'May',
  JUNE = 'June',
  JULY = 'July',
  AUGUST = 'August',
  SEPTEMBER = 'September',
  OCTOBER = 'October',
  NOVEMBER = 'November',
  DECEMBER = 'December',
}

export interface Date {
  month: Months;
  year: number;
}

export interface Poem {
  title: string;
  subtitle?: string;
  verses: string[][];
  dateComposed: Date;
}
