import React from 'react';
import styled from '@emotion/styled';
import { Movie as MovieType } from '../interfaces';
import { mediaMd, mediaSm } from '../../constants';

function Movie({ movie }: { movie: MovieType }) {
  return (
    <Container>
      <Name>{movie.name}</Name>
      <ContentWrapper>
        <MovieImage src={movie.imageUrl} />
        <About>{movie.about}</About>
      </ContentWrapper>
    </Container>
  );
}

export default Movie;

const Container = styled.div`
  font-family: 'Montserrat';
  font-size: 0.9rem;
  border-radius: 8px;
  margin-bottom: 12px;
  background: rgba(255, 255, 255, 0.6);
  width: 450px;
  padding: 8px 16px;
`;

const ContentWrapper = styled.div`
  margin-top: 8px;
  display: flex;

  ${mediaSm} {
    flex-direction: column;
  }
`;

const Name = styled.div`
  font-size: 1.1rem;
  font-weight: bold;
  text-align: center;
  border-bottom: 2px solid #2323d3;
`;

const MovieImage = styled.img`
  height: 125px;
  object-fit: contain;
  border-radius: 4px;
  ${mediaSm} {
    margin-bottom: 8px;
  }
`;

const About = styled.div`
  margin-left: 12px;
  display: flex;
  justify-content: center;
  align-items: center;

  ${mediaMd} {
    font-size: 0.9rem;
  }
`;
