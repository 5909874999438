import React from 'react';
import styled from '@emotion/styled';
import {
  Interest,
  InterestAbout,
  InterestHeader,
  InterestTitle,
} from '../helpers';
import Movie from './Movie';
import { MOVIES } from '../constants';
import { CameraMovie } from '@styled-icons/boxicons-regular/CameraMovie';

function Movies() {
  return (
    <Container>
      <InterestHeader>
        <InterestTitle>Movies</InterestTitle>
        <MovieIcon />
      </InterestHeader>
      <InterestAbout>
        To me, movies have always been one of the most powerful ways of
        conveying emotion -- combining stellar cinematography with a good,
        punchy soundtrack and thoughtful dialogues. The movies that leave me the
        most inspired are the ones that make me rethink life, love, death, and
        everything in between.
      </InterestAbout>
      <MoviesWrapper>
        {MOVIES.map((movie) => {
          return <Movie movie={movie} />;
        })}
      </MoviesWrapper>
    </Container>
  );
}

export default Movies;

const Container = styled(Interest)``;

const MoviesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-top: 32px;
`;

const MovieIcon = styled(CameraMovie)`
  color: white;
  margin-left: 12px;
  height: 50px;
  width: 50px;
`;
