import React from 'react';
import styled from '@emotion/styled';
import { Research as ResearchType } from './interfaces';
import { mediaMd, mediaSm } from '../constants';

function ResearchProject({ research }: { research: ResearchType }) {
  const { startDate, endDate } = research;
  const hasLink = research.website ? true : false;
  return (
    <Container href={research.website} target="_blank" hasLink={hasLink}>
      <Header>
        <Title>{research.name}</Title>
        <Date>
          {startDate.month
            ? `${startDate.month} ${startDate.year} - `
            : `${startDate.year} - `}
          {endDate === 'Present'
            ? 'Present'
            : endDate.month
            ? `${endDate.month} ${endDate.year}`
            : endDate.year}
        </Date>
      </Header>
      <ContentWrapper>
        <Image src={research.imageUrl} />
        <Divider />
        <About>{research.about}</About>
      </ContentWrapper>
    </Container>
  );
}

export default ResearchProject;

const Container = styled.a<{ hasLink: boolean }>`
  font-family: 'Montserrat';
  color: black;
  text-decoration: none;
  font-size: 0.9rem;
  border-radius: 8px;
  margin-bottom: 20px;
  background: rgba(255, 255, 255, 0.5);
  padding: 8px 16px 16px 16px;
  transition: transform 120ms linear, background 120ms linear,
    box-shadow 120ms linear;

  :hover {
    transform: ${({ hasLink }) => (hasLink ? 'translateY(-6px)' : null)};
    background: ${({ hasLink }) =>
      hasLink ? 'rgba(255, 255, 255, 0.9)' : null};
    box-shadow: ${({ hasLink }) =>
      hasLink ? '6px 6px 6px rgba(0, 0, 0, 0.4)' : null};
  }

  :last-of-type {
    margin-bottom: 0px;
  }
`;

const Divider = styled.div`
  background: var(--purple);
  height: 150px;
  min-width: 2px;
  margin: 0 28px;

  ${mediaMd} {
    height: 0px;
    min-width: 0px;
    max-width: 0px;
  }
`;

const Image = styled.img`
  height: 180px;
  width: 300px;
  object-fit: cover;
  border-radius: 8px;

  ${mediaMd} {
    margin: 12px 0px;
  }

  ${mediaSm} {
    height: 150px;
    width: 250px;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  ${mediaMd} {
    flex-direction: column;
  }
`;

const About = styled.div`
  font-size: 0.9rem;
`;

const Header = styled.div`
  position: relative;
`;

const Date = styled.div`
  position: absolute;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  padding: 4px;
  top: 0;
  font-size: 0.8rem;
  right: 0;

  ${mediaMd} {
    position: static;
    text-align: center;
    margin-top: 6px;
  }
`;

const Title = styled.div`
  font-size: 1.3rem;
  text-align: center;
  font-weight: bold;
`;
