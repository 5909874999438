import styled from '@emotion/styled';
import { mediaMd, mediaSm } from '../constants';

export const Interest = styled.div`
  margin-bottom: 30px;
  :last-of-type {
    margin-bottom: 0px;
  }
`;

export const InterestTitle = styled.text`
  font-family: 'Montserrat';
  font-size: 3rem;
  font-weight: bold;
  background: -webkit-linear-gradient(45deg, white, var(--accent));
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  ${mediaMd} {
    font-size: 2.7rem;
  }
`;

export const InterestAbout = styled.div`
  font-family: 'Montserrat';
  margin: 8px 0px;
  text-align: center;
  font-size: 1.05rem;
  ${mediaMd} {
    font-size: 1rem;
  }
`;

export const InterestHeader = styled.div`
  display: flex;
  align-items: center;
  ${mediaSm} {
    justify-content: center;
  }
`;
