import React from 'react';
import styled from '@emotion/styled';
import { QUOTES } from '../constants';
import {
  Interest,
  InterestAbout,
  InterestHeader,
  InterestTitle,
} from '../helpers';
import { Quote as QuoteType } from '../interfaces';
import Quote from './Quote';
import { getNextPianoNote } from '../../../1-sounds/audioLibrary';
import { QuotesLeft } from '@styled-icons/icomoon/QuotesLeft';
import { QuotesRight } from '@styled-icons/icomoon/QuotesRight';

function Quotes() {
  return (
    <Container>
      <InterestHeader>
        <InterestTitle>Quotes</InterestTitle>
        <LeftQuote />
        <RightQuote />
      </InterestHeader>
      <InterestAbout>
        I have a quirky habit of writing down funny things my friends say. We go
        through the list every couple months to reminisce and laugh about the
        jokes again!
      </InterestAbout>
      <InterestAbout style={{ marginTop: '-4px' }}>
        <b>Fun Tip: Hover over the quotes to play different piano notes!</b>
      </InterestAbout>
      <QuotesWrapper>
        {QUOTES.map((quote: QuoteType) => {
          return <Quote quote={quote} sound={getNextPianoNote()} />;
        })}
      </QuotesWrapper>
    </Container>
  );
}

export default Quotes;

const Container = styled(Interest)``;

const QuotesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-top: 32px;
`;

const LeftQuote = styled(QuotesLeft)`
  margin-left: 12px;
  height: 40px;
  width: 40px;
  color: white;
`;

const RightQuote = styled(QuotesRight)`
  margin-left: 12px;
  height: 40px;
  width: 40px;
  color: white;
`;
