import React from 'react';
import styled from '@emotion/styled';
import { Date } from './interfaces';
import { mediaMd, mediaSm } from '../constants';

function Poem({
  title,
  verses,
  dateComposed,
}: {
  title: string;
  verses: string[][];
  dateComposed: Date;
}) {
  return (
    <Container>
      <ContentWrapper>
        {verses.map((verse) => {
          return (
            <Verse>
              {verse.map((line) => {
                return <Line dangerouslySetInnerHTML={{ __html: line }} />;
              })}
            </Verse>
          );
        })}
      </ContentWrapper>
    </Container>
  );
}

export default Poem;

const Container = styled.div`
  border-radius: 2rem;
  overflow: hidden;
  margin: 2rem;
  margin-top: 0.5rem;
  ${mediaSm} {
    margin: 1rem;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0rem 2rem 1.5rem 2rem;
  background: rgba(255, 255, 255, 0.5);

  ${mediaMd} {
    max-width: 400px;
  }

  ${mediaSm} {
    padding: 0rem 1rem 1rem 1rem;
    max-width: 300px;
  }
`;

const Verse = styled.div`
  margin-top: 1.3rem;
  font-size: 0.9rem;
`;

const Line = styled.div`
  white-space: pre-wrap;
`;
