import { Months } from '../Poems/interfaces';
import { Publication } from './interfaces';

export const PUBLICATIONS: Publication[] = [
  {
    title: 'How to Optimize Learning Online',
    publisher: 'The Unico Project',
    about:
      'This is an article I wrote <b>post-pandemic</b> about how I <b>adjusted</b> to the <b>online learning environment</b>. I reflected on my experiences as both a <b>college student</b> and a <b>course assistant</b> for a university writing course.',
    datePublished: {
      month: Months.AUGUST,
      year: 2020,
    },
    url: 'https://www.theunicoproject.com/post/how-to-optimize-learning-online',
    type: 'article',
  },
  {
    title: 'Tongue-tied Ways to Die',
    publisher: 'The Unorthodocs',
    about:
      'In this poem, I explore <b>surrealism</b> through <b>nightmarish scenarios of death</b>.',
    datePublished: {
      month: Months.AUGUST,
      year: 2020,
    },
    url: 'https://www.theunorthodocs.org/tongue-tied-ways-to-die',
    type: 'poem',
  },
  {
    title: 'grocery shopping for independence',
    publisher: 'Pause, LSWA Journal 2019-2020',
    about:
      'Two of my poems were <b>selected</b> to be <b>published in the annual journal</b> of my living-learning creative community, <b>Lloyd Scholars for Writing and the Arts</b>.',
    url: 'https://issuu.com/lhsp/docs/lswa_2019-20_pause',
    type: 'poem',
  },
  {
    title: 'If You Seek a Pleasant Peninsula, Look About You',
    publisher: 'The Unico Project',
    about:
      'This is a <b>coming-of-age story</b> of my <b>experience moving all over the world</b> from house to house, until I reached my own <b>independent space in college</b>. I grapple with what my <b>idea of ‘home’</b> is: an <b>elusive concept</b> I am yet to figure out!',
    datePublished: {
      month: Months.JULY,
      year: 2020,
    },
    url:
      'https://www.theunicoproject.com/post/if-you-seek-a-pleasant-peninsula-look-about-you-a-coming-of-age-story',
    type: 'article',
  },
  {
    title: 'self portrait as the women who precede me',
    publisher: 'The Unorthodocs',
    about:
      'This is a poem <b>very close to my heart</b>, where I take a <b>close look at myself</b> as a product of the <b>wonderful women who precede me</b>: my <b>mother</b> and my <b>grandmother</b>.',
    datePublished: {
      month: Months.JULY,
      year: 2020,
    },
    url: 'https://www.theunorthodocs.org/self-portrait',
    type: 'poem',
  },
  {
    title: 'The Secret to Beauty',
    publisher: 'The Unorthodocs',
    about:
      'Here is a poem about the <b>vicious side of beauty</b>, especially when you may <b>not look like the conventional image</b>.',
    datePublished: {
      month: Months.NOVEMBER,
      year: 2020,
    },
    url: 'https://www.theunorthodocs.org/the-secret-to-beauty',
    type: 'poem',
  },
];

export const LONG_PUBLICATIONS: string[] = [
  'If You Seek a Pleasant Peninsula, Look About You',
  'self portrait as the women who precede me',
];
