import React from 'react';
import styled from '@emotion/styled';
import {
  Container,
  ContentWrapper,
  GradientText,
  HeaderBoilerplate,
  IntroBoilerplate,
  Title,
} from '../helpers';
import NavigationBar from '../NavigationBar';
import ResearchProject from './ResearchProject';
import { RESEARCH } from './constants';
import { mediaSm } from '../constants';

function Research() {
  return (
    <Container>
      <NavigationBar />
      <ContentWrapper>
        <Header>
          <Title>
            <GradientText>My Research</GradientText>
          </Title>
          <Intro>
            As a future <b>psychologist</b>,{' '}
            <b>research is very important to me</b>. I think it is among the{' '}
            <b>most noble work</b> that is integral to our understanding of
            science, the world, and human nature. My personal research interests
            include <b>creativity and creative therapy</b>,{' '}
            <b>gender and feminist psychology</b>, and <b>PTSD and trauma</b>.
          </Intro>
          <ActionText>
            Here is some of my <b>most impactful research</b>!
          </ActionText>
        </Header>
        <ResearchListWrapper>
          {RESEARCH.map((research) => {
            return <ResearchProject research={research} />;
          })}
        </ResearchListWrapper>
      </ContentWrapper>
    </Container>
  );
}

export default Research;

const Intro = styled(IntroBoilerplate)``;

const Header = styled(HeaderBoilerplate)``;

const ResearchListWrapper = styled.div`
  margin-top: 2rem;
  padding: 0px 2rem;
  display: flex;
  flex-direction: column;
`;

const ActionText = styled(IntroBoilerplate)`
  margin-top: 1.5rem;
  text-align: center;
`;
