import { Howl } from 'howler';
import { AudioConfig } from './interfaces';

function getAudio(url: string, config?: AudioConfig): Howl {
  const sound = new Howl({ src: url, ...config });
  return sound;
}

const PIANO_NOTES: string[] = ['a', 'b', 'c', 'd', 'e', 'f', 'g'];

const pianoSounds: Howl[] = PIANO_NOTES.map((note) => {
  return getAudio(`/sounds/piano-${note}.wav`, { volume: 0.01 });
});

function createPianoNoteGenerator(): () => Howl {
  let index = 0;
  return () => pianoSounds[index++ % PIANO_NOTES.length];
}

export const getNextPianoNote = createPianoNoteGenerator();
