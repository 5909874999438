import React from 'react';
import styled from '@emotion/styled';
import {
  Container,
  ContentWrapper,
  GradientText,
  HeaderBoilerplate,
  IntroBoilerplate,
  Title,
} from '../helpers';
import NavigationBar from '../NavigationBar';
import Publication from './Publication';
import { PUBLICATIONS } from './constants';
import { mediaSm } from '../constants';

function Publications() {
  return (
    <Container>
      <NavigationBar />
      <ContentWrapper>
        <Header>
          <Title>
            <GradientText>My Publications</GradientText>
          </Title>
          <Intro>
            Here are some words that I have written for{' '}
            <b>online publications</b> and <b>journals</b>!
          </Intro>
          <ActionText>
            <b>Click on different boxes</b> below to read some of my{' '}
            <b>most popular publications</b>!
          </ActionText>
        </Header>
        <PublicationListWrapper>
          {PUBLICATIONS.map((publication) => {
            return <Publication publication={publication} />;
          })}
        </PublicationListWrapper>
      </ContentWrapper>
    </Container>
  );
}

export default Publications;

const Intro = styled(IntroBoilerplate)``;

const Header = styled(HeaderBoilerplate)``;

const PublicationListWrapper = styled.div`
  margin-top: 2rem;
  padding: 0px 2rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;

  ${mediaSm} {
    padding: 0px 1rem;
  }
`;

const ActionText = styled(IntroBoilerplate)`
  margin-top: 1.5rem;
  text-align: center;
`;
