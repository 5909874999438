import React from 'react';
import styled from '@emotion/styled';
import { NAVIGATION, NavItem } from '../constants';
import { Link, useLocation } from 'react-router-dom';

function DesktopNav() {
  const location = useLocation();
  return (
    <Container>
      <NavOptionsWrapper>
        {Object.values(NAVIGATION).map((navItem: NavItem) => {
          return (
            <NavOption
              isCurrentPage={location.pathname === navItem.route}
              to={navItem.route}
            >
              {navItem.menuOption}
            </NavOption>
          );
        })}
      </NavOptionsWrapper>
    </Container>
  );
}

export default DesktopNav;

const Container = styled.div`
  width: 100%;
  position: fixed;
  top: 0;
  margin: 0 auto;
  z-index: 10;
  background: rgba(242, 242, 242, 0.7);
`;

const NavOptionsWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  border-radius: 100px;
`;

const NavOption = styled(Link)<{ isCurrentPage: boolean }>`
  color: ${({ isCurrentPage }) => (isCurrentPage ? 'var(--accent)' : 'black')};
  text-decoration: none;
  font-family: 'Montserrat';
  font-size: 1rem;
  padding: 0.7rem 0.2rem 0.7rem 0.2rem;
  transition: color 200ms ease-in, transform 200ms ease-in,
    text-shadow 200ms ease-in;

  ::after {
    content: '';
    width: 0px;
    height: 1.5px;
    display: block;
    background: black;
    transition: background 200ms ease-in, width 200ms ease-in;
  }
  :hover {
    color: var(--accent);
    ::after {
      width: 100%;
      background: var(--accent);
    }
  }
`;
