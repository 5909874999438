import React from 'react';
import styled from '@emotion/styled';
import {
  Interest,
  InterestAbout,
  InterestHeader,
  InterestTitle,
} from '../helpers';
import { MusicNoteBeamed } from '@styled-icons/bootstrap/MusicNoteBeamed';

function Music() {
  return (
    <Container>
      <InterestHeader>
        <InterestTitle>Music</InterestTitle>
        <BigMusicNote />
        <MediumMusicNote />
        <SmallMusicNote />
      </InterestHeader>
      <InterestAbout>
        Enjoy these top 20 songs from my list of over 1700! Also, all 64 of my
        playlists start with a lowercase letter.
      </InterestAbout>
      <SpotifyWrapper>
        <Spotify
          style={{ borderRadius: '12px' }}
          src="https://open.spotify.com/embed/playlist/3lN1ZcBEeMTEXfySB6TWSk"
          width="800"
          height="450"
          frameBorder="0"
          allowTransparency={true}
          allow="encrypted-media"
        />
      </SpotifyWrapper>
    </Container>
  );
}

export default Music;

const Container = styled(Interest)``;

const SpotifyWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
`;

const Spotify = styled.iframe``;

const BigMusicNote = styled(MusicNoteBeamed)`
  color: white;
  margin-left: 12px;
  height: 40px;
  width: 40px;
`;

const MediumMusicNote = styled(BigMusicNote)`
  height: 30px;
  width: 30px;
  margin-left: 8px;
  margin-top: -16px;
`;

const SmallMusicNote = styled(MediumMusicNote)`
  height: 20px;
  width: 20px;
  margin-left: 8px;
  margin-top: -32px;
`;
