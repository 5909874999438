import styled from '@emotion/styled';
import { mediaMd, mediaSm } from './constants';

export const Container = styled.div`
  height: calc(100vh - 43px);
  width: 100vw;
  overflow-y: auto;
  margin-top: 43px;

  ${mediaMd} {
    height: 100vh;
    margin-top: 0px;
  }
`;

export const ContentWrapper = styled.div`
  padding: 1.5rem 4rem 3rem 4rem;
  position: relative;

  ${mediaMd} {
    padding: 1rem 2rem 2rem 2rem;
  }

  ${mediaSm} {
    padding: 1.8rem 1rem 1rem 1rem;
  }
`;

export const GradientText = styled.text`
  font-size: inherit;
  font-weight: inherit;
  background: -webkit-linear-gradient(45deg, var(--accent), var(--purple));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export const HeaderBoilerplate = styled.div`
  padding: 0px 2rem;
  font-family: 'Montserrat';
  ${mediaMd} {
    padding: 0px 0rem;
  }
`;

export const Title = styled.div`
  font-size: 4rem;
  font-weight: bold;
  padding-bottom: 0.5rem;
  ${mediaMd} {
    text-align: center;
    font-size: 3.5rem;
  }
  ${mediaSm} {
    font-size: 3rem;
  }
`;

export const IntroBoilerplate = styled.div`
  font-size: 1.1rem;
  color: black;
  ${mediaMd} {
    font-size: 1rem;
    text-align: center;
  }
`;

export const TextLink = styled.a`
  color: var(--accent);
`;

export const Row = styled.div`
  display: flex;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const EvenlySpacedColumn = styled(Column)`
  justify-content: space-evenly;
`;
