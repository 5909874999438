import React, { useEffect } from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import styled from '@emotion/styled';
import Home from './Home';
import About from './About';
import { NAVIGATION } from './constants';
import Poems from './Poems';
import Contact from './Contact';
import Resume from './Resume';
import Experience from './Experience';
import Research from './Research';
import Publications from './Publications';

let initialWindowWidth = window.innerWidth;
window.addEventListener('resize', () => {
  if (initialWindowWidth > 900 && window.innerWidth < 900) {
    window.location.reload();
  }
  if (initialWindowWidth < 900 && window.innerWidth > 900) {
    window.location.reload();
  }
});

export default function Root() {
  return (
    <Container>
      <Router>
        <Switch>
          <Route path={NAVIGATION.ABOUT.route} component={About} />
          <Route path={NAVIGATION.POEMS.route} component={Poems} />
          <Route path={NAVIGATION.EXPERIENCE.route} component={Experience} />
          <Route path={NAVIGATION.RESEARCH.route} component={Research} />
          <Route
            path={NAVIGATION.PUBLICATIONS.route}
            component={Publications}
          />
          <Route path={NAVIGATION.RESUME.route} component={Resume} />
          <Route path={NAVIGATION.CONTACT.route} component={Contact} />
          <Route path="/" component={Home} />
        </Switch>
      </Router>
    </Container>
  );
}

const Container = styled.div``;
