import { Research } from './interfaces';
import { Months } from '../Poems/interfaces';

export const RESEARCH: Research[] = [
  {
    name: 'University of Michigan',
    startDate: {
      month: Months.FEBRUARY,
      year: 2020,
    },
    endDate: 'Present',
    imageUrl: '/research/feminisms-project.jpg',
    about:
      'Since Winter 2020, at the University of Michigan, I have been working closely with Dr. Abigail Stewart of the Psychology faculty on her Global Feminisms Project. I transcribe interviews with great women who have contributed to the women’s rights movement from all over the world and also annotate other transcribed interviews by conducting research on the area and its politics to provide cultural context.',
    website: 'https://sites.lsa.umich.edu/globalfeminisms/',
  },
  {
    name: 'Job Shadow',
    startDate: {
      month: Months.JANUARY,
      year: 2019,
    },
    endDate: {
      month: Months.DECEMBER,
      year: 2019,
    },
    imageUrl: '/research/symbiosis.png',
    about:
      'I later shadowed my school counsellor and used her guidance to conduct research on the learning styles of students in my school to optimise their education. I worked with her and created surveys, which I distributed to my high school, along with the appropriate consent forms and debriefing. I later shared the results with them and informed them what the best study techniques would be for them based on their learning style.',
  },
  {
    name: 'IB Diploma Programme Research',
    startDate: {
      year: 2017,
    },
    endDate: {
      year: 2019,
    },
    imageUrl: '/research/ib.png',
    about:
      "Beginning in high school, I was a student of the IB Diploma Programme, where I had to conduct extensive research for my Internal Assignments in six subjects over two years, including replications of a psychological experiment investigating the effect of disgust on morality; investigating the effect of the motifs 'fixing' and 'repairing' in the play Death and the Maiden, and using the Fourier series to plot a graph of an ECG heart monitor. I also wrote a 4000 word Extended Essay in Psychology examining the difference in psychological abuse experienced by women in relationships of different sexual orientations.",
  },
];
