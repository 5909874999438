import { Experience } from './interfaces';
import { Months } from '../Poems/interfaces';

export const EXPERIENCES: Experience[] = [
  {
    company: 'Global Feminisms Project',
    position: 'Research Assistant',
    description:
      'I have been working closely with Dr. Abigail Stewart on the Global Feminisms Project of the University of Michigan. I have the unique privilege of transcribing and annotating interviews conducted with inspiring women from all over the world. I have worked on transcripts from Nigeria, Germany and Brazil.',
    startDate: {
      month: Months.FEBRUARY,
      year: 2020,
    },
    endDate: 'Present',
    image: '/experience/feminisms-project.jpg',
    website: 'https://sites.lsa.umich.edu/globalfeminisms/',
    isWideImage: true,
  },
  {
    company: 'The Unorthodocs',
    position: 'Director of Social Media',
    description:
      'The Unorthodocs is an online literary magazine. I am responsible for finding thought-provoking content for Instagram, as well as conducting and executing research on how to increase our customer base. I work closely with the founders of the magazine to help develop the vision of encouraging out-of-the-box, truly bizarre forms of creativity.',
    startDate: {
      month: Months.OCTOBER,
      year: 2020,
    },
    endDate: 'Present',
    image: '/experience/unorthodocs.png',
    website: 'https://www.theunorthodocs.org/',
  },
  {
    company: 'Lloyd Scholars for Writing and the Arts (LSWA)',
    position: 'Student Assistant',
    description:
      'After spending a year as a student in the Michigan Learning Community (MLC) Lloyd Scholars for Writing and the Arts (LSWA), I returned as a student leader. My responsibilities include fostering a safe and healthy environment for the creative community and hosting wellness events. I co-lead a weekly club called the Arts and Literary Journal Club, which is responsible for curating the annual community journal released at the end of the year.',
    startDate: {
      month: Months.AUGUST,
      year: 2020,
    },
    endDate: 'Present',
    image: '/experience/lswa.png',
    website: 'https://lsa.umich.edu/lswa/people/student-leaders/rachnai.html',
  },
  {
    company: 'Comprehensive Studies Program (CSP)',
    position: 'Writing Course Assistant',
    description:
      'Over the summer of 2020, I spent three months as a Course Assistant for CSP 105, a writing course with the Community Scholars Program Summer Bridge Program designed to provide incoming freshmen with writing guidance to best equip them for college academic writing. I hosted biweekly writing workshops independently where I got to work more closely with the students on individual writing processes.',
    startDate: {
      month: Months.JUNE,
      year: 2020,
    },
    endDate: {
      month: Months.AUGUST,
      year: 2020,
    },
    image: '/experience/csp.png',
    website: 'https://lsa.umich.edu/csp/about-us.html',
  },
  {
    company: 'University of Michigan - Department of Psychology',
    position: 'Memory Support Services Volunteer',
    description:
      'For one semester, as a student of Psychology 211: Project Outreach - Mind, Music and Community, I got to spend a few hours each week volunteering at Evangelical Home, a resident home in Saline, Michigan. I played the guitar and piano while other members of my group sang and played other instruments. We also got to interact with residents, many of whom were suffering from varying forms of dementia; participate in activities with them and apply knowledge of music therapy from the course to practice.',
    startDate: {
      month: Months.SEPTEMBER,
      year: 2019,
    },
    endDate: {
      month: Months.DECEMBER,
      year: 2019,
    },
    image: '/experience/psych.jpg',
    website: 'https://sites.lsa.umich.edu/projectoutreach/',
  },
  {
    company: 'Michigan Dining',
    position: 'Food Service Worker',
    description:
      'Working as a dining service worker in the Law School of the University of Michigan taught me so many invaluable skills, such as how to communicate clearly with other members of the team (such as the chefs) and, more importantly, how to get my hands dirty and clean counters and dishes!',
    startDate: {
      month: Months.SEPTEMBER,
      year: 2019,
    },
    endDate: {
      month: Months.DECEMBER,
      year: 2019,
    },
    image: '/experience/dining.jpg',
    website: 'https://dining.umich.edu/',
  },
  {
    company: 'Self Employed',
    position: 'Tutor',
    description:
      'During breaks from high school, I would spend my days tutoring students from my neighbourhood and juniors from my own schools. I created my own mini-business model, including syllabi, systems of distribution of notes and resources, and criteria for transportation.',
    startDate: {
      month: Months.APRIL,
      year: 2017,
    },
    endDate: {
      month: Months.AUGUST,
      year: 2019,
    },
    image: '/experience/self-employed.png',
  },
  {
    company: 'W.A.Y.S. AWARENESS',
    position: 'Founder and CEO',
    description:
      'I started a mental health awareness campaign in high school, with the purpose of destigmatizing mental illness amongst the youth of India. I worked on spreading awareness on social media through testimonials and providing resources, as well as fundraising at public events.',
    startDate: {
      month: Months.MARCH,
      year: 2017,
    },
    endDate: {
      month: Months.MARCH,
      year: 2018,
    },
    image: '/experience/ways.jpg',
    website: 'https://instagram.com/waysawareness?igshid=jq56z4f0jjfx',
  },
  {
    company: 'Infinity IPS',
    position: 'H.R. Intern',
    description:
      'Over the summer, I worked as an H.R. intern for Infinity IPS, a financial services corporation. I was responsible for creating and proctoring aptitude tests for applicants to entry-level positions. I also conducted some job interviews for prospective applicants, and regularly monitored job search websites to find potential candidates.',
    startDate: {
      month: Months.APRIL,
      year: 2017,
    },
    endDate: {
      month: Months.JUNE,
      year: 2017,
    },
    image: '/experience/infinity-ips.jpg',
    website: 'https://infinity-data.com/',
  },
  {
    company: 'Teach For India',
    position: 'Project Development Intern',
    description:
      'Over the course of three years, I have done some of the work I am proudest of with Sunrise Community Center, a learning center for less privileged students in Pune, India. I have formed close, lifelong bonds with students and the teachers I worked with. I painted multiple wall murals, initiated a PenPal Project and started music lessons at the center. Here are some of the murals my students and I painted together!',
    startDate: {
      month: Months.MAY,
      year: 2016,
    },
    endDate: {
      month: Months.AUGUST,
      year: 2019,
    },
    image: '/experience/teach-for-india.png',
    website: 'https://www.teachforindia.org/',
    isWideImage: true,
  },
];
