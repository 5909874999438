import React from 'react';
import styled from '@emotion/styled';
import { Experience as ExperienceType } from './interfaces';
import { Row } from '../helpers';
import { mediaMd, mediaSm } from '../constants';

function WorkExperience({ experience }: { experience: ExperienceType }) {
  const hasLink = experience.website ? true : false;
  const isTFA = experience.company === 'Teach For India';
  return (
    <Container href={experience.website} target="_blank" hasLink={hasLink}>
      <ContentWrapper>
        <Image src={experience.image} isWide={experience.isWideImage} />
        <Divider />
        <About>
          <Header>
            <Company>{experience.company}</Company>
            <ConditionalRow>
              <Position>{experience.position}</Position>
              <Date>
                {`${experience.startDate.month} ${experience.startDate.year} - `}
                {experience.endDate == 'Present'
                  ? `${experience.endDate}`
                  : `${experience.endDate.month} ${experience.endDate.year}`}
              </Date>
            </ConditionalRow>
          </Header>
          <Description>{experience.description}</Description>
        </About>
      </ContentWrapper>
      {isTFA && (
        <>
          <MuralWrapper>
            <Mural src="/experience/mural-1.jpg" />
            <Mural src="/experience/mural-2.jpg" />
          </MuralWrapper>
        </>
      )}
    </Container>
  );
}

export default WorkExperience;

const Container = styled.a<{ hasLink: boolean }>`
  position: relative;
  font-family: 'Montserrat';
  color: black;
  text-decoration: none;
  font-size: 0.9rem;
  border-radius: 8px;
  margin-bottom: 20px;
  background: rgba(255, 255, 255, 0.5);
  padding: 16px 16px 16px 16px;
  transition: transform 120ms linear, background 120ms linear,
    box-shadow 120ms linear;

  :hover {
    transform: ${({ hasLink }) => (hasLink ? 'translateY(-6px)' : null)};
    background: ${({ hasLink }) =>
      hasLink ? 'rgba(255, 255, 255, 0.9)' : null};
    box-shadow: ${({ hasLink }) =>
      hasLink ? '6px 6px 6px rgba(0, 0, 0, 0.4)' : null};
  }

  :last-of-type {
    margin-bottom: 0px;
  }
`;

const Divider = styled.div`
  background: var(--purple);
  height: 150px;
  min-width: 2px;
  margin: 0 28px;

  ${mediaMd} {
    height: 0px;
    min-width: 0px;
    max-width: 0px;
  }
`;

const Image = styled.img<{ isWide: boolean | undefined }>`
  height: 200px;
  width: ${({ isWide }) => (isWide ? '300px' : '200px')};
  object-fit: fill;
  border-radius: 8px;
  ${mediaMd} {
    margin-bottom: 8px;
    height: 175px;
    width: ${({ isWide }) => (isWide ? '275px' : '175px')};
  }
  ${mediaSm} {
    margin-bottom: 8px;
    height: 150px;
    width: ${({ isWide }) => (isWide ? '250px' : '150px')};
  }
`;

const ConditionalRow = styled.div`
  ${mediaMd} {
    display: flex;
    justify-content: space-between;
  }
  ${mediaSm} {
    display: block;
    margin-top: 4px;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;

  ${mediaMd} {
    flex-direction: column;
  }
`;

const About = styled.div`
  font-size: 0.9rem;
`;

const Date = styled.div`
  position: absolute;
  background: rgba(0, 0, 0, 0.13);
  border-radius: 4px;
  padding: 4px;
  right: 16px;
  bottom: 12px;
  font-size: 0.8rem;

  ${mediaMd} {
    position: static;
    display: inline-block;
  }

  ${mediaSm} {
    margin-top: 4px;
  }
`;

const Company = styled.div`
  font-size: 1.8rem;
  font-weight: bolder;
  ${mediaMd} {
    margin-bottom: 4px;
  }
  ${mediaSm} {
    font-size: 1.3rem;
  }
`;

const Position = styled.div`
  color: var(--accent);
  font-size: 1.2rem;
  font-weight: bold;
  ${mediaSm} {
    font-size: 1.1rem;
  }
`;

const Header = styled.div`
  margin-bottom: 16px;
`;

const Description = styled.div`
  font-size: 0.9rem;
`;

const MuralWrapper = styled(Row)`
  margin-top: 16px;
  margin-bottom: 24px;
  padding: 0px 2rem;
  justify-content: space-evenly;
  ${mediaSm} {
    flex-direction: column;
  }
`;

const Mural = styled.img`
  height: 300px;
  object-fit: contain;
  border-radius: 8px;
  ${mediaMd} {
    height: 275px;
  }
  ${mediaSm} {
    height: 200px;
    margin-bottom: 16px;
    :last-of-type {
      margin-bottom: 0px;
    }
  }
`;
