import React from 'react';
import styled from '@emotion/styled';
import {
  Container,
  ContentWrapper,
  GradientText,
  HeaderBoilerplate,
  IntroBoilerplate,
  Title,
} from '../helpers';
import NavigationBar from '../NavigationBar';
import { Link } from 'react-router-dom';
import { Download } from '@styled-icons/boxicons-solid/Download';
import { mediaMd, mediaSm, NAVIGATION } from '../constants';

function Resume() {
  return (
    <Container>
      <NavigationBar />
      <ContentWrapper>
        <Header>
          <Title>
            <GradientText>My Resume</GradientText>
            <DownloadResume href="/Rachna_Iyer_Resume.pdf" download>
              <DownloadIcon />
            </DownloadResume>
          </Title>
          <Intro>
            If you want to read more about the things I've done, take a look at
            my <TextLink to={NAVIGATION.RESEARCH.route}>research</TextLink>,{' '}
            <TextLink to={NAVIGATION.POEMS.route}>poems</TextLink>, and{' '}
            <TextLink to={NAVIGATION.EXPERIENCE.route}>
              work experience
            </TextLink>
            !
          </Intro>
        </Header>
        <ResumeWrapper>
          <ResumeImage src="/resume.jpg" />
        </ResumeWrapper>
      </ContentWrapper>
    </Container>
  );
}

export default Resume;

const Intro = styled(IntroBoilerplate)`
  text-align: center;
  font-size: 1.3rem;
`;

const Header = styled(HeaderBoilerplate)``;

const TextLink = styled(Link)`
  color: var(--accent);
  text-decoration: underline;
`;

const ResumeWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const ResumeImage = styled.img`
  object-fit: contain;
  margin-top: 40px;
  width: 70%;
  border-radius: 20px;
  ${mediaMd} {
    width: 90%;
  }
  ${mediaSm} {
    width: 100%;
  }
`;

const DownloadResume = styled.a`
  ${mediaSm} {
    display: block;
  }
`;

const DownloadIcon = styled(Download)`
  height: 65px;
  width: 65px;
  color: white;
  background: var(--accent);
  border-radius: 20px;
  margin-left: 30px;

  ${mediaMd} {
    position: static;
    margin: 0px;
    margin-left: 30px;
  }
  ${mediaSm} {
    margin-top: 20px;
    height: 50px;
    width: 50px;
    margin: 0px;
    border-radius: 10px;
  }
`;
