import React from 'react';
import styled from '@emotion/styled';
import {
  Container,
  ContentWrapper,
  GradientText,
  HeaderBoilerplate,
  IntroBoilerplate,
  TextLink,
  Title,
} from '../helpers';
import NavigationBar from '../NavigationBar';
import Collapsible from '../Collapsible';
import { PoemList, PoemsNeedingElongation } from './constants';
import Poem from './Poem';
import { mediaMd, mediaSm } from '../constants';

function Poems() {
  return (
    <Container>
      <NavigationBar />
      <ContentWrapper>
        <Header>
          <Title>
            <GradientText>My Poetry</GradientText>
          </Title>
          <Intro>
            Although I only started writing in 2019 after being inspired by my
            living-learning community{' '}
            <TextLink href="https://lsa.umich.edu/lswa" target="_blank">
              Lloyd Scholars for Writing and the Arts
            </TextLink>
            , I have grown immensely as a poet in this past year.
          </Intro>
          <Intro>
            Fun fact: in Sanskrit, the name{' '}
            <i>
              <b>Rachna</b>
            </i>{' '}
            means
            <i>
              {' '}
              <b>to create, form, make, write or compose</b>
            </i>{' '}
            and in an interesting turn of events, I have become a creative
            enthusiast myself!
          </Intro>
          <ActionText>
            Click on different Titles below to peruse some of my best work!
          </ActionText>
        </Header>
        <PoemListWrapper>
          {PoemList.map((poem) => {
            const needsElongation = PoemsNeedingElongation.includes(poem.title);
            return (
              <CollapsibleWrapper needsElongation={needsElongation}>
                <Collapsible
                  title={poem.title}
                  subtitle={poem.subtitle}
                  date={poem.dateComposed}
                >
                  <Poem
                    title={poem.title}
                    verses={poem.verses}
                    dateComposed={poem.dateComposed}
                  />
                </Collapsible>
              </CollapsibleWrapper>
            );
          })}
        </PoemListWrapper>
      </ContentWrapper>
    </Container>
  );
}

export default Poems;

const PoemListWrapper = styled.div`
  padding: 0px 2rem;
  display: flex;
  flex-direction: column;
  justify-content: left;

  ${mediaSm} {
    padding: 0rem;
  }
`;

const Intro = styled(IntroBoilerplate)`
  margin-top: 10px;
`;

const CollapsibleWrapper = styled.div<{ needsElongation: boolean }>`
  max-width: ${(props) => (props.needsElongation ? '85%' : '75%')};
  margin-top: 1.5rem;
  border-radius: 1rem;

  ${mediaMd} {
    max-width: 100%;
  }

  ${mediaSm} {
    margin-bottom: 0.5rem;
  }
`;

const ActionText = styled(Intro)`
  margin-top: 2rem;
  text-align: center;
`;

const Header = styled(HeaderBoilerplate)`
  padding-bottom: 10px;
`;
