import React from 'react';
import styled from '@emotion/styled';
import { Book as BookType } from '../interfaces';

function Book({ book }: { book: BookType }) {
  return (
    <Container>
      <Header>
        <Title>{book.title}</Title>
        <Author>{`~${book.author}`}</Author>
      </Header>
      <ContentWrapper>
        <About dangerouslySetInnerHTML={{ __html: book.about }} />
        {book.quote && <Quote>{book.quote}</Quote>}
      </ContentWrapper>
    </Container>
  );
}

export default Book;

const Container = styled.div`
  font-family: 'Montserrat';
  font-size: 0.9rem;
  border-radius: 8px;
  margin-bottom: 12px;
  background: rgba(255, 255, 255, 0.6);
  width: 450px;
  padding: 8px 16px;
`;

const Header = styled.div`
  border-bottom: 2px solid #2323d3;
`;

const Title = styled.div`
  font-size: 1.1rem;
  font-weight: bold;
  text-align: center;
`;

const Author = styled.div`
  font-size: 0.8rem;
  text-align: right;
`;

const About = styled.div`
  font-size: 0.85rem;
`;

const ContentWrapper = styled.div`
  margin-top: 8px;
  display: flex;
  flex-direction: column;
`;

const Quote = styled.div`
  margin-top: 4px;
  font-size: 0.85rem;
  padding: 4px 8px;
  box-sizing: border-box;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.13);
`;
