import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Link, useLocation } from 'react-router-dom';
import { NAVIGATION, NavItem } from '../constants';

function NavMenu({ showMenu }: { showMenu: boolean }) {
  const location = useLocation();
  return (
    <Container showMenu={showMenu}>
      <PortraitWrapper showMenu={showMenu}>
        <Portrait src="/portrait.jpg" />
      </PortraitWrapper>
      <MenuWrapper showMenu={showMenu}>
        <Menu>
          {Object.values(NAVIGATION).map((navItem: NavItem, index) => (
            <MenuOption
              to={navItem.route}
              delay={(index + 1.35) * 0.095}
              showMenu={showMenu}
              currentPage={location.pathname === navItem.route}
            >
              {navItem.menuOption}
            </MenuOption>
          ))}
        </Menu>
      </MenuWrapper>
    </Container>
  );
}

export default NavMenu;

const Container = styled.div<{ showMenu: boolean }>`
  position: fixed;
  height: 100vh;
  width: 100vw;
  display: flex;
  transition: ${({ showMenu }) => (showMenu ? null : 'z-index 1ms ease-out')};
  transition-delay: ${({ showMenu }) => (showMenu ? null : '500ms')};
  z-index: ${({ showMenu }) => (showMenu ? 1 : -1)};
  flex-direction: column;
`;

const PortraitWrapper = styled.div<{ showMenu: boolean }>`
  flex: 1;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  transition: transform 500ms ease-out;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 0px;
  transform: translate3d(
    ${({ showMenu }) => (showMenu ? '0, 0, 0' : '100%, 0, 0')}
  );
`;

const MenuWrapper = styled(PortraitWrapper)`
  background: rgba(0, 0, 0, 0.7);

  flex: 3;
  transform: translate3d(
    ${({ showMenu }) => (showMenu ? '0, 0, 0' : '-100%, 0, 0')}
  );
  box-sizing: border-box;
`;

const Portrait = styled.img`
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: solid 3px var(--accent);
`;

const Menu = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;

const MenuOption = styled(Link)<{
  showMenu: boolean;
  delay: number;
  currentPage: boolean;
}>`
  display: inline-block;
  text-align: center;
  text-decoration: none;
  font-size: 1.5rem;
  text-transform: uppercase;
  color: ${({ currentPage }) => (currentPage ? 'var(--accent)' : 'white')};
  font-weight: bold;
  cursor: pointer;
  transition: transform 500ms ease-out ${({ delay }) => `${delay}s`},
    color 200ms ease-in;

  :hover {
    color: var(--accent);
    ::after {
      width: 100%;
      background: var(--accent);
    }
  }

  transform: translate3d(
    ${({ showMenu }) => (showMenu ? '0, 0, 0' : '-600px, 0, 0')}
  );

  ::after {
    content: '';
    width: 0px;
    height: 1.5px;
    display: block;
    background: black;
    transition: background 200ms ease-in, width 200ms ease-in;
  }
`;
