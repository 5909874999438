import React from 'react';
import styled from '@emotion/styled';
import { Publication as PublicationType } from './interfaces';
import { useState } from 'react';
import { LONG_PUBLICATIONS } from './constants';
import { mediaSm } from '../constants';

function Publication({ publication }: { publication: PublicationType }) {
  const [isHovering, setIsHovering] = useState(false);
  let month = '';
  let year = 0;
  if (publication.datePublished) {
    month = publication.datePublished.month;
    year = publication.datePublished.year;
  }
  const isLongName = LONG_PUBLICATIONS.includes(publication.title);
  return (
    <Container
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      href={publication.url}
      target="_blank"
    >
      <WhiteBlur hover={isHovering} />
      <BackdropWrapper hover={isHovering} isLong={isLongName}>
        <BackdropText hover={isHovering} isPoem={publication.type === 'poem'}>
          {publication.type}
        </BackdropText>
      </BackdropWrapper>
      <ContentWrapper>
        <Title>{publication.title}</Title>
        <About
          isPoem={publication.type === 'poem'}
          dangerouslySetInnerHTML={{ __html: publication.about }}
        />
        <Publisher>
          Published by {publication.publisher}
          {publication.datePublished ? ` on ${month}, ${year}` : ''}
        </Publisher>
      </ContentWrapper>
    </Container>
  );
}

export default Publication;

const Container = styled.a`
  font-family: 'Montserrat';
  color: black;
  text-decoration: none;
  font-size: 0.9rem;
  border-radius: 8px;
  margin-bottom: 20px;
  background: rgba(255, 255, 255, 0.7);
  transition: transform 150ms linear, background 150ms linear,
    box-shadow 150ms linear;
  width: 450px;
  height: 200px;
  position: relative;
  overflow: hidden;
  z-index: 0;

  ${mediaSm} {
    height: auto;
  }

  :hover {
    transform: translateY(-6px);
    background: rgba(255, 255, 255, 0.75);
    box-shadow: 6px 6px 6px rgba(0, 0, 0, 0.4);
  }
`;

const ContentWrapper = styled.div`
  padding: 0px 16px 0px 16px;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const BackdropWrapper = styled.div<{ hover: boolean; isLong: boolean }>`
  position: absolute;
  z-index: ${({ hover }) => (hover ? '2' : '-1')};
  top: ${({ hover, isLong }) => (hover ? '0' : isLong ? '10px' : '0')};
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: z-index 150ms linear, top 150ms linear;
`;

const About = styled.div<{ isPoem: boolean }>`
  padding: 8px;
  text-align: center;
  border-radius: 8px;
  b {
    color: ${({ isPoem }) => (isPoem ? 'var(--accent)' : 'var(--purple)')};
  }
`;

const WhiteBlur = styled.div<{ hover: boolean }>`
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: ${({ hover }) =>
    hover ? 'rgba(255, 255, 255, 0.9)' : 'transparent'};
  transition: background 150ms linear;
`;

const BackdropText = styled.div<{ hover: boolean; isPoem: boolean }>`
  font-weight: bold;
  text-transform: uppercase;
  transition: font-size 150ms linear, opacity 150ms linear, color 150ms linear;
  font-size: ${({ hover }) => (hover ? '6rem' : '0rem')};
  opacity: ${({ hover }) => (hover ? '1' : '0')};
  color: ${({ hover, isPoem }) =>
    hover ? (isPoem ? 'var(--accent)' : 'var(--purple)') : 'var(--accent)'};
`;

const Publisher = styled.div`
  text-align: center;
  background: rgba(0, 0, 0, 0.2);
  padding: 4px;
  border-radius: 4px;
  font-size: 0.8rem;
  margin-bottom: 8px;
`;

const Title = styled.div`
  margin-top: 8px;
  font-size: 1.25rem;
  text-align: center;
  font-weight: bold;
`;
