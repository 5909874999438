enum NAV_OPTIONS {
  HOME = 'HOME',
  ABOUT = 'ABOUT',
  POEMS = 'POEMS',
  EXPERIENCE = 'EXPERIENCE',
  RESEARCH = 'RESEARCH',
  PUBLICATIONS = 'PUBLICATIONS',
  RESUME = 'RESUME',
  CONTACT = 'CONTACT',
}

export interface NavItem {
  menuOption: string;
  route: string;
}

type NavigationType = {
  [key in NAV_OPTIONS]: NavItem;
};

interface Navigation extends NavigationType {}

export const NAVIGATION: Navigation = {
  HOME: {
    menuOption: 'Home',
    route: '/',
  },
  ABOUT: {
    menuOption: 'About Me',
    route: '/about',
  },
  POEMS: {
    menuOption: 'Poems',
    route: '/poems',
  },
  EXPERIENCE: {
    menuOption: 'Experience',
    route: '/experience',
  },
  RESEARCH: {
    menuOption: 'Research',
    route: '/research',
  },
  PUBLICATIONS: {
    menuOption: 'Publications',
    route: '/publications',
  },
  RESUME: {
    menuOption: 'Resume',
    route: '/resume',
  },
  CONTACT: {
    menuOption: 'Contact Me',
    route: '/contact',
  },
};

export const mediaSm = '@media (max-width: 500px)';
export const mediaMd = '@media (max-width: 900px)';
